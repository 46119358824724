@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700);
/* ------------------------------------ */
/* Global Button Styles                 */
/* ------------------------------------ */

.vs-btn-d {
  background-color: transparent;
  border: 1px solid transparent;
  border-collapse: separate;
  border-radius: 3px;
  box-shadow: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin: 0 4px;
  height: 32px;
  min-width: -webkit-min-content;
  min-width: min-content;
  overflow: hidden;
  padding: 4px 12px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: auto;
}

.vs-btn-d:focus,
a.vs-btn-d:focus {
  outline: none;
}

a.vs-btn-d:hover,
.vs-btn-d a:hover {
  text-decoration: none;
}

.vs-btn-d svg {
  fill: #979c9f;
  height: 20px;
  transition: 0.2s all ease-in-out;
  vertical-align: middle;
  width: 20px;
}

.vs-btn-d:hover svg {
  fill: #4d5051;
}

/* ------------------------------------ */
/* Deprecated MemberWeb Buttons         */
/* ------------------------------------ */

.vs-default-btn {
  border: 1px solid #b6b6b6;
  color: #b6b6b6;
}
.vs-default-btn:hover {
  background: rgba(133, 133, 133, 0.1);
  box-shadow: none;
}
.vs-primary-btn {
  border: 1px solid #49c5b1;
  color: #49c5b1;
}
.vs-primary-btn:hover {
  background: rgba(0, 175, 102, 0.1);
  box-shadow: none;
}
.vs-info-btn {
  border: 1px solid #34657f;
  color: #34657f;
}
.vs-info-btn:hover {
  background: rgba(52, 101, 127, 0.1);
  box-shadow: none;
}
.vs-danger-btn {
  border: 1px solid #e1523d;
  color: #e1523d;
}
.vs-danger-btn:hover {
  background: rgba(221, 56, 52, 0.1);
  box-shadow: none;
}
.vs-warning-btn {
  border: 1px solid #ffb500;
  color: #ffb500;
}
.vs-warning-btn:hover {
  background: rgba(255, 181, 20, 0.1);
  box-shadow: none;
}
.vs-success-btn {
  background: #ffffff;
  border: 1px solid #49c5b1;
  box-sizing: border-box;
  color: #49c5b1;
  outline: none;
}
.vs-success-btn:hover {
  background: rgba(73, 197, 177, 0.1);
  box-shadow: none;
}
.vs-outline-primary-btn {
  background: #fff;
  border-color: #49c5b1;
  color: #49c5b1;
}
.vs-outline-primary-btn:hover {
  background: rgba(73, 197, 176, 0.1);
  color: #35b09c;
}

/* ------------------------------------ */
/* Platform Buttons                     */
/* ------------------------------------ */

/* ------------------------------------ */
/* Primary Solid Buttons                      */
/* ------------------------------------ */

.vs-solid-primary-btn {
  background-color: #49c5b1;
  background-position: center;
  color: #fff;
  transition: background 0.4s;
}

.vs-solid-primary-btn:hover {
  background: #14ab93 radial-gradient(circle, transparent 1%, #14ab93 1%)
    center/15000%;
}

.vs-solid-primary-btn:active {
  background-color: #44cbb4;
  background-size: 100%;
  transition: background 0s;
}

.vs-solid-primary-btn svg {
  fill: #fff;
  margin-left: -4px;
}

.vs-solid-primary-btn:hover svg {
  fill: #fff;
}

/* ------------------------------------ */
/* Primary Raised Buttons */
/* ------------------------------------ */

.vs-solid-primary-raised-btn {
  background-color: #49c5b1;
  background-position: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 2px 3px -1px rgba(0, 0, 0, 0.14);
  color: #fff;
  transition: 0.4s all ease-in-out;
}

.vs-solid-primary-raised-btn:hover {
  background: #14ab93 radial-gradient(circle, transparent 1%, #14ab93 1%)
    center/15000%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 2px 3px -1px rgba(0, 0, 0, 0.25);
}

.vs-solid-primary-raised-btn:active {
  background-color: #44cbb4;
  background-size: 100%;
  transition: background 0s;
}

.vs-solid-primary-raised-btn svg {
  fill: #fff;
  margin-left: -4px;
}

.vs-solid-primary-raised-btn:hover svg {
  fill: #fff;
}

/* ------------------------------------ */
/* Secondary Soild Button */
/* ------------------------------------ */

.vs-solid-secondary-btn {
  background-color: #616c78;
  background-position: center;
  color: #fff;
  transition: background 0.4s;
}

.vs-solid-secondary-btn:hover {
  background: #48535f radial-gradient(circle, transparent 1%, #48535f 1%)
    center/15000%;
}

.vs-solid-secondary-btn:hover {
  background-color: #48535f;
}

.vs-solid-secondary-btn:active {
  background-color: #75808c;
  background-size: 100%;
  transition: background 0s;
}

.vs-solid-secondary-btn svg {
  fill: #fff;
  margin-left: -4px;
}

.vs-solid-secondary-btn:hover svg {
  fill: #fff;
}

/* ------------------------------------ */
/* Pill Buttons */
/* ------------------------------------ */

.vs-solid-primary-pill-btn,
.vs-solid-secondary-pill-btn {
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.vs-solid-primary-pill-btn {
  background-color: #49c5b1;
  background-position: center;
  color: #fff;
  transition: background 0.4s;
}

.vs-solid-primary-pill-btn:hover {
  background: #14ab93 radial-gradient(circle, transparent 1%, #14ab93 1%)
    center/15000%;
}

.vs-solid-primary-pill-btn:active {
  background-color: #44cbb4;
  background-size: 100%;
  transition: background 0s;
}

.vs-solid-secondary-pill-btn {
  background-color: #616c78;
  background-position: center;
  color: #fff;
  transition: background 0.4s;
}

.vs-solid-secondary-pill-btn:hover {
  background: #48535f radial-gradient(circle, transparent 1%, #48535f 1%)
    center/15000%;
}

.vs-solid-secondary-pill-btn:hover {
  background-color: #48535f;
}

.vs-solid-secondary-pill-btn:active {
  background-color: #75808c;
  background-size: 100%;
  transition: background 0s;
}

.vs-solid-primary-pill-btn svg,
.vs-solid-secondary-pill-btn svg {
  fill: #fff;
  margin-left: -6px;
}

.vs-solid-primary-pill-btn:hover svg,
.vs-solid-secondary-pill-btn:hover svg {
  fill: #fff;
}

.vs-btn-d.vs-solid-outline-raised-pill-btn {
  background-color: #fff;
  border-color: transparent;
  border-radius: 16px;
}

/* ------------------------------------ */
/* Secondary Outline Buttons */
/* ------------------------------------ */

.vs-outline-secondary-btn,
.vs-outline-secondary-raised-btn,
.vs-rounded-outline-btn,
.vs-solid-outline-raised-pill-btn {
  border-color: #d8dcdf;
  background-position: center;
  color: #686f78;
  transition: all 0.4s;
}

.vs-outline-secondary-raised-btn,
.vs-solid-outline-raised-pill-btn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 2px 3px -1px rgba(0, 0, 0, 0.14);
  transition: 0.4s all ease-in-out;
}

.vs-outline-secondary-btn svg,
.vs-outline-secondary-raised-btn svg,
.vs-solid-outline-raised-pill-btn svg {
  margin-left: -4px;
}

.vs-outline-secondary-btn:hover,
.vs-rounded-outline-btn:hover,
.vs-solid-outline-raised-pill-btn:hover {
  background: rgba(137, 150, 165, 0.1)
    radial-gradient(circle, transparent 1%, rgba(137, 150, 165, 0.1) 1%)
    center/15000%;
  color: #414346;
}

.vs-outline-secondary-raised-btn:hover,
.vs-solid-outline-raised-pill-btn:hover {
  background: rgba(137, 150, 165, 0.1)
    radial-gradient(circle, transparent 1%, rgba(137, 150, 165, 0.1) 1%)
    center/15000%;
  color: #414346;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 2px 3px -1px rgba(0, 0, 0, 0.25);
}

.vs-outline-secondary-btn:active,
.vs-outline-secondary-raised-btn:active,
.vs-rounded-outline-btn:active,
.vs-solid-outline-raised-pill-btn:active {
  background-color: rgba(167, 179, 192, 0.04);
  background-size: 100%;
  color: #414346;
  transition: background 0s;
}

/* ------------------------------------ */
/* Round Circle Buttons */
/* ------------------------------------ */

.vs-rounded-solid-btn {
  background: #49c5b1;
  border-radius: 16px;
  color: #fff;
  height: 32px;
  padding: 0;
  width: 32px;
}

.vs-rounded-solid-btn:hover {
  background: #35b09c;
}

.vs-rounded-solid-btn svg {
  fill: #fff;
  /*   width: 16px; */
}

.vs-rounded-solid-btn:hover svg {
  fill: #fff;
}

.vs-rounded-outline-btn {
  border-radius: 16px;
  height: 32px;
  padding: 0;
  width: 32px;
}

.vs-rounded-outline-btn svg {
  /*   width: 16px; */
}

.vs-rounded-outline-btn:hover {
  background: rgba(172, 176, 181, 0.1);
  color: #6f7376;
}

/* ------------------------------------ */
/* Text Buttons */
/* ------------------------------------ */

.vs-text-btn {
  background: transparent;
  border-color: transparent;
  color: #6f7376;
  -webkit-align-items: center;
          align-items: center;
}

.vs-text-btn:hover {
  background: rgba(111, 115, 118, 0.1);
  color: #414346;
}

.vs-text-btn svg {
  margin-top: -4px;
}

.vs-text-link-btn {
  background-color: transparent;
  color: #148dd1;
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
}

.vs-text-link-btn:hover {
  background: transparent;
  color: #006dac;
}

.vs-icon-btn {
  background: transparent;
  border-color: transparent;
  border-radius: 16px;
  color: #6f7376;
  height: 32px;
  padding: 3px;
  vertical-align: middle;
  width: 32px;
}

.vs-icon-btn:hover {
  background: rgba(111, 115, 118, 0.1);
  color: #414346;
}

/* ------------------------------------ */
/* Dropdown Buttons */
/* ------------------------------------ */

.vs-inline-outline-secondary-btn {
  border-color: #d8dcdf;
  border-radius: 3px;
  font-weight: 400;
  font-size: 13px;
  height: 32px;
  line-height: 24px;
  padding: 3px 12px;
  text-transform: none;
}

.vs-inline-outline-secondary-btn:active {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.vs-inline-outline-secondary-btn:hover {
  border-color: #979c9f;
}

.vs-inline-outline-secondary-btn svg {
  margin-top: -2px;
}

.vs-inline-outline-secondary-btn .mdi-icon {
  width: 16px;
}

.vs-inline-button-icon {
  margin-left: -4px;
  margin-right: 6px;
}

.vs-inline-button-icon-only {
  margin-left: -6px;
  margin-right: -6px;
}

.vs-inline-button-icon,
.vs-inline-button-icon-only {
  width: 16px;
}

.vs-inline-outline-secondary-btn .vs-inline-button-chevron {
  margin-left: 4px;
  margin-right: -4px;
  width: 20px;
}

/* ------------------------------------ */
/* Button sizing */
/* ------------------------------------ */

.vs-btn-d.vs-sm-btn {
  font-size: 1rem;
  height: auto;
  line-height: 1;
  padding: 6px 8px;
}

.vs-btn-d.vs-lg-btn {
  padding: 12px 28px;
  height: 40px;
  line-height: 1;
}

/* ------------------------------------ */
/* Disabled state */
/* ------------------------------------ */

.vs-primary-btn.disabled,
.vs-primary-btn[disabled],
.vs-primary-btn.disabled:hover,
.vs-primary-btn[disabled]:hover,
.vs-solid-primary-pill-btn.disabled,
.vs-solid-primary-pill-btn[disabled],
.vs-solid-primary-pill-btn.disabled:hover,
.vs-solid-primary-pill-btn[disabled]:hover,
.vs-default-btn.disabled,
.vs-default-btn[disabled],
.vs-default-btn.disabled:hover,
.vs-default-btn[disabled]:hover {
  box-shadow: none;
  opacity: 0.55;
  filter: alpha(opacity=55);
  cursor: not-allowed;
}

.vs-primary-btn.disabled,
.vs-primary-btn[disabled],
.vs-primary-btn.disabled:hover,
.vs-primary-btn[disabled]:hover {
  background: rgba(0, 175, 102, 0.1);
}

.vs-outline-primary-btn.disabled,
.vs-outline-primary-btn[disabled],
.vs-outline-primary-btn.disabled:hover,
.vs-outline-primary-btn[disabled]:hover {
  background: transparent;
  border-color: #96d6cc;
  color: #afe3db;
}

.vs-default-btn.disabled,
.vs-default-btn[disabled],
.vs-default-btn.disabled:hover,
.vs-default-btn[disabled]:hover {
  background: rgba(133, 133, 133, 0.1);
}

.vs-btn-d.disabled,
.vs-btn-d[disabled],
.vs-btn-d.disabled:hover,
.vs-btn-d[disabled]:hover {
  border-color: transparent;
  cursor: not-allowed;
}

.vs-solid-primary-btn.disabled,
.vs-solid-primary-btn[disabled],
.vs-solid-primary-btn.disabled:hover,
.vs-solid-primary-btn[disabled]:hover,
.vs-solid-primary-btn.disabled,
.vs-solid-primary-btn[disabled],
.vs-solid-primary-btn.disabled:hover,
.vs-solid-primary-btn[disabled]:hover {
  color: #d4efea;
  background: #96d6cc;
}

.vs-solid-secondary-pill-btn.disabled,
.vs-solid-secondary-pill-btn[disabled],
.vs-solid-secondary-pill-btn.disabled:hover,
.vs-solid-secondary-pill-btn[disabled]:hover {
  color: #c0c2c4;
  background: #7f848a;
}

.vs-outline-secondary-btn.disabled,
.vs-outline-secondary-btn[disabled],
.vs-outline-secondary-btn.disabled:hover,
.vs-outline-secondary-btn[disabled]:hover {
  background: #edeef0;
  border-color: transparent;
  color: rgba(33, 33, 33, 0.34);
}

.vs-icon-btn.disabled svg,
.vs-icon-btn[disabled] svg,
.vs-icon-btn.disabled:hover svg,
.vs-icon-btn[disabled]:hover svg {
  fill: #ccd1d7;
}

.vs-icon-btn.disabled,
.vs-icon-btn[disabled],
.vs-icon-btn.disabled:hover,
.vs-icon-btn[disabled]:hover {
  background: transparent;
}

.vs-text-btn.disabled,
.vs-text-btn[disabled],
.vs-text-btn.disabled:hover,
.vs-text-btn[disabled]:hover {
  background: transparent;
  color: #ccd1d7;
}

.vs-text-link-btn.disabled,
.vs-text-link-btn[disabled],
.vs-text-link-btn.disabled:hover,
.vs-text-link-btn[disabled]:hover {
  color: #ccd1d7;
}

/* remove margin if using inside a box */
.vs-box .vs-btn-d {
  margin: 0;
}

.vs-bs-container {
  text-align: center;
  padding: 64px 32px;
}

.vs-bs-container.vs-bs-leftalign {
  display: -webkit-flex;
  display: flex;
  text-align: left;
}

.vs-bs-container.vs-bs-leftalign .vs-bs-img {
  width: 128px;
}

.vs-bs-container.vs-bs-leftalign .vs-bs-content {
  padding-left: 24px;
  padding-top: 16px;
}

.vs-bs-container.vs-bs-leftalign h1.vs-bs-title {
  margin-top: 0;
  margin-bottom: 24px;
}

.vs-bs-container.vs-bs-leftalign .vs-bs-subtitle {
  margin-bottom: 24px;
}

.vs-bs-container.vs-bs-leftalign .vs-bs-subtitle p {
  margin-bottom: 8px;
}

.vs-bs-img svg {
  width: 128px;
  height: 128px;
  fill: #d8dcdf;
}

h1.vs-bs-title {
  font-size: 1.8rem;
  margin-bottom: 24px;
  margin-top: 32px;
}

.vs-bs-subtitle {
  color: #333;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 8px;
}

.vs-bs-description-text {
  color: #757575;
  margin: 0 auto 24px;
  max-width: 768px;
}

.vs-bs-button-primary {
  margin: 0 0 16px;
}

/* @license
 * MyFonts Webfont Build ID 2691808, 2013-11-19T16:06:51-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gibson Light by Canada Type
 * URL: http://www.myfonts.com/fonts/canadatype/gibson/light/

 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2691808
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2011 Rod McDonald. Published by Canada Type. All rights reserved.
 *
 * Â© 2013 MyFonts Inc
*/
@font-face {
  font-family: 'gibson-light';
  src: url(/static/media/gibson-light-webfont.a87665d3.woff2) format('woff2'),
    url(/static/media/gibson-light-webfont.ba481e12.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
/* @import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800'); */

/*
GLOBAL COLOR VARIABLES:
border-color: #c8ccce;
Brand Primary: #49c5b1
Brand Dark Blue: #151c23
Pink <code>: #eb3366
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
}

body {
  background-color: #f6f6f6;
  color: #333;
  font-family: 'Roboto', -system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #49c5b1;
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

::-moz-selection {
  background-color: rgba(73, 197, 176, 0.4);
  color: #000;
  text-shadow: none;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

/* Headings and Text Styling */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #333;
  font-family: inherit;
  font-weight: bold;
  font-weight: 500;
  line-height: 1.2;
  margin: 3.2rem 0 1.6rem;
}

h1,
.h1 {
  font-size: 2.8rem;
  margin-bottom: 2.4rem;
}

h2,
.h2 {
  font-size: 2.4rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.8rem;
}

h5,
.h5 {
  font-size: 1.6rem;
}

h6,
.h6 {
  font-size: 1.4rem;
}

h1:first-child,
h2:first-child {
  margin-top: 0;
}

h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

.lead {
  color: #777;
  font-size: 1.8rem;
  font-weight: 300;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1.6rem;
  font-style: normal;
  line-height: inherit;
}

hr {
  border: 0;
  border-top: 1px solid #e1e1e1;
  box-sizing: content-box;
  height: 0;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  overflow: visible;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

ol,
ul {
  padding-left: 3.2rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.8rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1.6rem;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-style: italic;
  border-left: 4px solid #e1e1e1;
}

b,
strong {
  font-weight: bolder;
}

del {
  text-decoration: line-through;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2893bc;
  text-decoration: none;
  background-color: transparent;
  transition: 0.2s all ease-in-out;
}

a:hover {
  color: #2893bc;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 4px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

pre,
pre code {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #c8ccce;
  border-radius: 2px;
  color: #333;
  display: block;
  font-size: 1.3rem;
  line-height: 1.6;
  margin: 0 0 1.6rem;
  overflow-x: auto;
  padding: 0.8rem 1.6rem;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  background-color: #f9f2f4;
  border-radius: 3px;
  color: #c7254e;
  display: inline-block;
  font-size: 90%;
  padding: 2px 4px;
}

pre code {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  display: inline;
  font-size: inherit;
  margin: 0;
  overflow-x: auto;
  padding: 0;
  white-space: pre;
  word-break: normal;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #151c23;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}

figure {
  margin: 0 0 1.6rem;
}

caption {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

img {
  border-style: none;
  max-width: 100%;
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: 500;
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.8rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 4px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*! --- START CJ Bootstrap Overrides --- */

.nav {
  margin-left: 0;
}

.navbar {
  height: 50px;
  margin-bottom: 5px;
}

/*Global styles to solve for other styles being removed*/

.btn {
  display: inline-block;
  padding: 4px 8px 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #6d6e70;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background: #ffffff;
  background: linear-gradient(to bottom, #ffffff 0%, #e9e9e9 50%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
  -webkit-filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  border: 1px solid #dbdcde !important;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.05);
}

.dropdown-selected-label {
  border-right: 1px solid #e4e4e4;
  padding-right: 12px;
}

.page-header {
  padding-bottom: 9px;
  margin: 20px 0 20px;
  border-bottom: 1px solid hsl(0, 0%, 93%);
}

.popover {
  padding: 0;
  border: 2px solid #00af66;
  width: auto;
  max-width: 100%;
}

.popover.bottom .arrow,
.popover.bottom .arrow:after {
  border-bottom-color: #00af66;
}

.popover.top .arrow,
.popover.top .arrow:after {
  border-top-color: #00af66;
}

.popover.right .arrow,
.popover.right .arrow:after {
  border-right-color: #00af66;
}

.popover.left .arrow,
.popover.left .arrow:after {
  border-left-color: #00af66;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

/*! --- END CJ Bootstrap Overrides --- */

fieldset p {
  position: relative;
  display: block;
  overflow: hidden;
}

p > label {
  float: left;
  padding: 0 0 0 18px;
}

label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 500;
  max-width: 100%;
  margin: 0 0 0.4rem;
}

label span {
  font-size: 1.4rem;
  font-weight: 400;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}

select,
textarea,
input[type='file'],
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  background-color: #fff;
  border: 1px solid #c8ccce;
  border-radius: 3px;
  color: #333;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  height: 32px;
  line-height: 1.6;
  margin-bottom: 0.8rem;
  max-width: 100%;
  padding: 2px 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

select:focus,
textarea:focus,
input[type='file']:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: #49c5b1;
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}

select.input-error,
textarea.input-error,
input.input-error[type='file'],
input.input-error[type='text'],
input.input-error[type='password'],
input.input-error[type='datetime'],
input.input-error[type='datetime-local'],
input.input-error[type='date'],
input.input-error[type='month'],
input.input-error[type='time'],
input.input-error[type='week'],
input.input-error[type='number'],
input.input-error[type='email'],
input.input-error[type='url'],
input.input-error[type='search'],
input.input-error[type='tel'],
input.input-error[type='color'] {
  border-color: #e1523d;
  box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}

input[type='radio'] + label,
input[type='checkbox'] + label {
  font-weight: 400;
  margin: 0 1.6rem 0 0;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 0.6rem 0.8rem 0.8rem 0;
  line-height: normal;
}

input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: thin dotted 9;
  outline: 4px auto -webkit-focus-ring-color;
  /*   outline-offset: -2px; */
}

input[type='color'] {
  border-radius: 2px;
  padding: 0 2px;
  height: 24px;
  width: 24px;
}

input[type='file'] {
  display: block;
  font-size: 1.2rem;
  height: auto;
  padding: 4px;
}

input[type='range'] {
  display: block;
  padding: 4px;
  width: 100%;
}

optgroup {
  font-weight: bold;
}

output {
  display: block;
  padding-top: 8px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #333;
}

progress {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

textarea {
  overflow: auto;
  min-height: 64px;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  color: #888;
  cursor: not-allowed;
  background-color: #eee;
  border-color: #e1e1e1;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}

::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #999;
  opacity: 1;
}

::placeholder {
  color: #999;
  opacity: 1;
}

#whole-page {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
}

#contentContainer {
  width: auto;
  padding-bottom: 100px;
  padding-top: 1em;
}

.content {
  padding: 0 1em;
}

.topBar {
  background-color: #99cb01;
  height: 8px;
}

.topLinks {
  width: 100%;
  float: right;
  text-align: right;
}

.grid-container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .grid-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .grid-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .grid-container {
    width: 1170px;
  }
}

.grid-container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.grid-row:before,
.grid-row:after {
  display: table;
  line-height: 0;
  content: '';
}

.grid-row:after {
  clear: both;
}

@media (min-width: 1200px) {
  .grid-row:before,
  .grid-row:after {
    display: table;
    line-height: 0;
    content: '';
  }
}

@media (min-width: 1200px) {
  .grid-row:after {
    clear: both;
  }
}

/* 	
 * Bootstrap Grid v3.3.6 
 */

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: ' ';
}
.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: ' ';
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}

:root {
    /* Directly named colors */
    --cj-green: #49c581;
    --dark-grey-blue: #254a5d;
    --greeny-blue: #49c5b1;
    --dark: #151c23;
    --gunmetal: #424c56;
    --black: #333333;
    --warm-grey: #757575;
    --cool-grey: #babbbd;
    --silver: #d8dcdf;
    --nice-blue: #1a6fba;
    --butterscotch: #ffb038;
    --bluey-green: #2bb179;
    --pastel-red: #e25959;
    --white: #ffffff;
  
    /* Maintainable Naming Convention */
    /*   1) order (example: primary, secondary, tertiary, quaternary, quinary, etc) */
    /*   2) role (example: brand, button, table-heading, etc) */
    /*   3) state (example: active, inactive, default, disabled, on-hover, etc) */
    /*   4) css property (example: color, font-family, margin, etc) */
  
    --primary-brand-color: var(--cj-green);
    --primary-button-color: var(--cj-green);
  
    --primary-body-text-color: var(--black);
    --heading-color: var(--black);
    --table-heading-active-color: var(--black);
    --svg-fill-on-hover-color: var(--black);
  
    --secondary-body-text-color: var(--warm-grey);
    --table-heading-defualt-color: var(--warm-grey);
    --table-heading-inactive-color: var(--warm-grey);
    --svg-fill-defualt-color: var(--warm-grey);
  
    --text-link-color: var(--nice-blue);
  
    --panel-color: var(--white);
    --container-color: var(--white);
    --primary-light-text-color: var(--white);
  
    --page-background-color: #f6f6f6;
    --table-row-highlight: #f6f6f6;
  
    --border-color: var(--silver);
  
    --button-background-disabled-color: #edeef0;
  
    --button-text-disabled-color: #a7a7a7;
  
    --badge-color: #6d7684;
    --indicator-color: #6d7684;
  
    --dialog-window-background-color: #4d5051;
  
    --light-text-color: #bebfc0;
  
    --validation-success-color: var(--bluey-green);
  
    --validation-error-color: var(--pastel-red);
  
    --primary-font-family: "Roboto", "-system-ui", "-apple-system",
      "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
